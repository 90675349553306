import React from "react"
import { Link } from "gatsby"
// import { Link, useStaticQuery, graphql } from "gatsby"
// import parse from "html-react-parser"
import Nav from "./nav"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ isHomePage, children }) => {

  // const {
  //   wp: {
  //     generalSettings: { title },
  //   },
  // } = useStaticQuery(graphql`
  //   query LayoutQuery {
  //     wp {
  //       generalSettings {
  //         title
  //         description
  //       }
  //     }
  //   }
  // `)

  return (
    <>
    <div className="master">
      <Nav />
      <main className="flex-grow flex flex-col m-auto px-5">{children}</main>
      <footer className="footer">
        <div className="footer-nav">
          <div className="footer-col flex-1 justify-start">
            <Link to="/">
              <StaticImage
                  src="../images/logo_light.png"
                  height={38}
                  layout="fixed"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Optiek De Leersnijder"
                  placeholder="none"
                  className="lg:block"
              />
            </Link>
          </div>
          <div className="footer-col my-6 text-center">
            {/* <Link to="/contact" className="hover:text-tdb-light">Contact</Link> */}
            <div className="flex justify-center">
                <a href="https://www.instagram.com/eyecatchonline/" target="_blank" rel="noreferrer" className="hover:text-dl-orange px-3" title="Volg Optiek De Leersnijder op Instagram ...">
                  <svg 
                    className="w-7 h-7 fill-current inline-block mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512">
                      {/* Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) */}
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                  </svg>
                </a>
                <a href="https://www.facebook.com/optiekdeleersnijder" target="_blank" rel="noreferrer" className="hover:text-dl-orange px-3" title="Volg Optiek De Leersnijder op Facebook ...">
                  <svg 
                    className="w-7 h-7 fill-current inline-block mr-2"
                    xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 512 512">
                    {/* Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) */}
                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                  </svg>
                </a>
              </div>
          </div>
          <div className="footer-col justify-start">
            <ul className="list-none text-center my-6 md:my-0 md:text-right text-sm">
              <li>Optiek De Leersnijder</li>
              <li>Bredabaan 419c</li>
              <li>2990 Wuustwezel</li>
              <li className="mt-4"><a href="tel:+3236696321" className="hover:text-dl-orange">+32(0)3 669 63 21</a></li>
              <li><Link to="/contact" className="hover:text-dl-orange">info@optiekdeleersnijder.be</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-copy">
          <Link to="/cookies" className="mt-10 text-xs hover:text-dl-orange">Cookie policy</Link>&nbsp;|&nbsp;
          <Link to="/privacy" className="text-xs hover:text-dl-orange">Privacy policy</Link>&nbsp;|&nbsp;
          © {new Date().getFullYear()} - Optiek De Leersnijder
        </div>
      </footer>
    </div>
    </>
  )
}

export default Layout
